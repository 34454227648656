const defaultConfig = require('tailwindcss/defaultConfig');

const responsiveVariants = ['sm', 'md', 'lg', 'xl', '2xl', 'responsive'];

module.exports = {
    theme: {
        colors: {
            'primary': '#48bfc3',
            'secondary': '#e2f5f9',
            'transparent': 'transparent',
            'black': '#646363',
            'white': '#ffffff',
            'green-light': '#51d88a',
            'green-lighter': '#B0D6BD',
            'green-lightest': '#cdf5da',
            'grey': '#b8c2cc',
            'grey-dark': '#929497',
            'grey-darker': '#706f6f',
            'grey-darkest': '#3d4852',
            'grey-light': '#dae1e7',
            'grey-lighter': '#f1eff0',
            'grey-lightest': '#f8fafc',
            'blue-100': '#dcf1ee',
            'orange-light': '#faad63',
            'pink': '#d60461',
            'pink-light': '#ec65a2',
            'pink-lighter': '#f392bd',
            'pink-500': '#f1637a',
            'pink-400': '#f3a5ae',
            'pink-200': '#FAA7AF',
            'pink-100': '#FFF5F4',
            'red-dark': '#7f2a27',
            'red-light': '#ef5753',
            'red-lightest': '#f6d5d5',
            'paypal-blue': '#003087',
        },
        screens: {
            'xs': '480px',
            'sm': '576px',
            'md': '768px',
            'lg': '992px',
            'xl': '1200px',
            '2xl': '1440px',
        },
        fontFamily: {
            script: [
                'Mellony Dry Brush',
                'serif',
            ],
            sans: [
                'Raleway',
                'sans-serif',
            ],
            heading: [
                'gelica',
                'sans-serif',
            ],
            leading: [
                'Raleway',
                'sans-serif',
            ],
        },
        fontSize: {
            'xs': '0.75rem',
            'sm': '0.875rem',
            'base': '1rem',
            'lg': '1.125rem',
            'xl': '1.25rem',
            '2xl': '1.5rem',
            '3xl': '1.875rem',
            '4xl': '2.25rem',
            '5xl': '3rem',
            '6xl': '4rem',
        },
    },
    plugins: [
        require('glhd-tailwindcss-transitions')(),
    ],
    content: [
        './pages/**/*.{js,jsx,ts,tsx,vue}',
        './components/**/*.{js,jsx,ts,tsx,vue}',
        './layouts/**/*.{js,jsx,ts,tsx,vue}',
    ],
    safelist: [
        'text-center',
        {
            pattern: /^grid-cols-/,
            variants: responsiveVariants,
        },
        {
            pattern: /^col-start-/,
            variants: responsiveVariants,
        },
        {
            pattern: /^col-span-/,
            variants: responsiveVariants,
        },
        {
            pattern: /^row-span-/,
            variants: responsiveVariants,
        },
        {
            pattern: /^grid-cols-/,
            variants: responsiveVariants,
        },
        {
            pattern: /^-?[mp][trblxy]-/,
            variants: responsiveVariants,
        },
        {
            pattern: /^gap-/,
            variants: responsiveVariants,
        },
        {
            pattern: /^w-/,
            variants: responsiveVariants,
        },
        {
            pattern: /^text-/,
        },
        {
            pattern: /^font-/,
        },
        {
            pattern: /^bg-/,
        },
    ],
};
