<template>
    <button
        :disabled="disabled"
        class="text-white font-semibold flex justify-center items-center focus:ring font-heading"
        :class="[`bg-${bg}`, classes]"
        @click="handleClick"
    >
        <slot name="icon" />
        <span
            v-if="text || $slots.default"
            class="leading-none"
        >
            <slot>{{ text }}</slot>
        </span>
        <Spinner
            v-if="loading"
            size="16"
            class="ml-3"
        />
    </button>
</template>

<script>
import Spinner from '~/components/Spinner';

export default {

    components: {
        Spinner,
    },

    props: {
        text: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        classes: { // TODO this is pointless lol
            type: String,
            default: '',
        },
        bg: {
            type: String,
            default: 'primary',
        },
    },

    methods: {
        handleClick() {
            this.$emit('click');
        },
    },

};
</script>

<style lang="scss" scoped>
button {
    @apply px-4 rounded transition-fast select-none flex h-10;
    &:hover {
        &:not(:disabled) {
            @apply opacity-75 shadow-md;
        }
    }
    &:disabled {
        @apply opacity-50 cursor-default;
    }
    @media (max-width: config("theme.screens.sm")) {
        @apply px-1;
        font-size: 90%;
        min-height: 2.5rem;
        height: auto;
    }
}
</style>
