import debounce from 'debounce';
import moment from 'moment';
import handleException from '../../plugins/error-handler.client';

export default {

    /**
     * Resets the store to its initial state.
     */
    reset({ commit }) {
        commit('reset');
    },

    async syncOrder({ commit, rootGetters, dispatch }) {
        const order = this.$cookies.get('basket');
        const basket = await this.$axios.$get(`/basket/${order.id}`);
        await dispatch('basket/setBasket', basket, { root: true });
        await dispatch('setOrder', basket);
    },

    /**
     * Prepopulates the checkout so that user details are pre-filled.
     * We step through the required fields and pre-fill them where possible and stopping once we reach a field that requires filling.
     */
    async prepopulate({ dispatch, rootGetters, getters }) {
        if (rootGetters['user/isLoggedIn']) {
            dispatch('setFirstName', getters.firstName.value || rootGetters['user/firstName']);
            dispatch('setLastName', getters.lastName.value || rootGetters['user/lastName']);
            dispatch('setEmail', getters.email.value || rootGetters['user/email']);
            dispatch('setPhone', getters.phone.value || rootGetters['user/phone']);

            await dispatch('setActiveStep', 'deliveryType');
        }
    },

    /**
     * Sets the active checkout step.
     */
    async setActiveStep({ dispatch, commit, getters }, stepName) {
        const element = document.getElementById(`${stepName}Section`);
        if (element) {
            setTimeout(() => element.scrollIntoView({ block: 'center', behavior: 'smooth' }), 100);
        }

        const { steps } = getters;
        // we reduce over the step object keys (the step names)
        const newSteps = Object.keys(steps).reduce((acc, name, index) => {
            // get the value for the current step
            const step = Object.values(steps)[index];

            if (name === stepName) {
                this.$bus.$emit('checkout', { step: index + 1, stepName });
            }

            // add a new step to our accumulator object
            acc[name] = {
                // spread the current step object and then override active and disabled, below
                ...step,
                // make the step active if it matches our stepName
                active: name === stepName,
                // enable the new step and keep others the way they are
                disabled: name === stepName ? false : step.disabled,
            };
            return acc;
        }, {});

        // set the updated steps in the store
        commit('setSteps', newSteps);

        await dispatch('updateOrder');
    },

    /**
     * Updates a step. We use this to make a step readonly or disabled.
     * Similar code to the setActiveStep above.
     */
    updateStep({ commit, getters }, { step: stepName, data }) {
        const { steps } = getters;
        const newSteps = Object.keys(steps).reduce((acc, name, index) => {
            let step = Object.values(steps)[index];
            if (name === stepName) {
                step = {
                    // spread the step
                    ...step,
                    // spread the data, overriding step properties
                    ...data,
                };
            }
            acc[name] = step;
            return acc;
        }, {});
        commit('setSteps', newSteps);
    },

    setFirstName({ commit }, firstName) {
        commit('setFirstName', firstName);
    },

    setLastName({ commit }, lastName) {
        commit('setLastName', lastName);
    },

    setEmail({ commit }, email) {
        commit('setEmail', email);
    },

    setPhone({ commit }, phone) {
        commit('setPhone', phone);
    },

    setGiftMessage({ commit }, giftMessage) {
        commit('setGiftMessage', giftMessage);
    },

    setShippingAddress({ commit, dispatch, getters }, shippingAddress) {
        commit('setShippingAddress', shippingAddress);

        // If the delivery step is active and a day has been chosen,
        // we will update the delivery options when the address changes.
        if (getters.delivery.active && !!getters.delivery.selectedDeliveryDay) {
            return dispatch('debouncedGetAvailableDeliveryOptions');
        }
    },

    setShippingAddressFirstName({ commit }, firstName) {
        commit('setShippingAddressFirstName', firstName);
    },

    setShippingAddressLastName({ commit }, lastName) {
        commit('setShippingAddressLastName', lastName);
    },

    setBillingAddress({ commit }, billingAddress) {
        commit('setBillingAddress', billingAddress);
    },

    setBillingAddressFirstName({ commit }, firstName) {
        commit('setBillingAddressFirstName', firstName);
    },

    setBillingAddressLastName({ commit }, lastName) {
        commit('setBillingAddressLastName', lastName);
    },

    setShowShippingAddressLookup({ commit }, showAddressLookup) {
        commit('setShowShippingAddressLookup', showAddressLookup);
    },

    setShowBillingAddressLookup({ commit }, showAddressLookup) {
        commit('setShowBillingAddressLookup', showAddressLookup);
    },

    setSelectedDeliveryDay({ commit }, selectedDeliveryDay) {
        commit('setSelectedDeliveryDay', selectedDeliveryDay);
        commit('setSelectedDeliveryOption', null);
        commit('setAvailableDeliveryOptions', []);
    },

    setSelectedDeliveryOption({ commit }, selectedDeliveryOption) {
        commit('setSelectedDeliveryOption', selectedDeliveryOption);
    },

    /**
     * Get delivery options and set the deliviery options error if there is one.
     */
    async getAvailableDeliveryOptions({ getters, dispatch, rootGetters }) {
        const { selectedDeliveryDay } = getters.delivery;
        const { options, message } = await this.$axios.$post('/shipping/rates', {
            date: selectedDeliveryDay,
            order_id: rootGetters['basket/id'],
            country_id: getters.shippingAddress.value.country.id,
            postcode: getters.shippingAddress.value.postcode,
        });
        await dispatch('setAvailableDeliveryOptions', options);
        await dispatch('setDeliveryOptionsError', message);
        return options;
    },

    /**
     * Used on the checkout to get delivery options after the shipping address changes.
     */
    debouncedGetAvailableDeliveryOptions: debounce(({ dispatch }) => dispatch('getAvailableDeliveryOptions'), 500),

    setAvailableDeliveryOptions({ commit }, availableDeliveryOptions) {
        commit('setAvailableDeliveryOptions', availableDeliveryOptions);
    },

    setDeliveryOptionsError({ commit }, deliveryOptionsError) {
        commit('setDeliveryOptionsError', deliveryOptionsError);
    },

    /**
     * Applies or removes the newsletter discount.
     */
    async toggleOptedIntoNewsletter({ rootGetters, dispatch }, val) {
        const id = rootGetters['basket/id'];
        let order = null;
        if (val) {
            order = await this.$axios.$post(`/basket/${id}/discount-code`, {
                is_for_newsletter: true,
            });
        } else {
            order = await this.$axios.$delete(`/basket/${id}/discount-code`);
        }
        await dispatch('basket/setBasket', order, { root: true });
    },

    async checkDeliveryAddressHasShippingRates({ commit }, { shippingAddress, productId }) {
        commit('setShippingAddress', shippingAddress);

        return this.$axios.post('/shipping/address', {
            ...shippingAddress,
            shipping_class_id: productId,
        })
            .then((response) => {
                const { available, price } = response.data;
                commit('setProductDeliveryAvailability', { productId, available, price });
            })
            .catch((error) => {
                handleException(error);
            });
    },

    async updateOrder({ rootGetters, getters }) {
        const id = rootGetters['basket/id'];

        try {
            await this.$axios.patch(`/orders/${id}`, getters.orderRequest);
        } catch (error) {
            handleException(error);
        }
    },

    async createOrder({ dispatch, getters }) {
        const response = await this.$axios.post('/orders', getters.orderRequest);

        dispatch('basket/setBasket', response.data, { root: true });
        dispatch('basket/setBasketCookie', response.data.id);
    },

    resetProductsChecked({ commit }) {
        commit('resetProductsChecked');
    },

    setDeliveryType({ commit }, value) {
        commit('setDeliveryType', value);
    },

    setOrder({ commit }, order) {
        commit('setFirstName', order.first_name);
        commit('setLastName', order.last_name);
        commit('setEmail', order.email);
        commit('setPhone', order.phone);
        commit('setShippingAddress', {
            firstName: order.address_first_name,
            lastName: order.address_last_name,
            firstLine: order.address_line_1,
            secondLine: order.address_line_2,
            thirdLine: order.address_line_3,
            company: order.address_company,
            city: order.address_city,
            county: order.address_county,
            country: {
                iso2: order.address_country_iso2,
            },
            postcode: order.address_postcode,
        });
        commit('setBillingAddress', {
            firstName: order.billing_address_first_name,
            lastName: order.billing_address_last_name,
            firstLine: order.billing_address_line_1,
            secondLine: order.billing_address_line_2,
            thirdLine: order.billing_address_line_3,
            company: order.billing_address_company,
            city: order.billing_address_city,
            county: order.billing_address_county,
            country: {
                iso2: order.billing_address_country_iso2,
            },
            postcode: order.billing_address_postcode,
        });
        commit('setSelectedDeliveryDay', order.ordered_for);
        commit('setSelectedDeliveryOption', order.shipping.id);
        commit('setDeliveryType', order.delivery_type);
        commit('setGiftMessage', order.gift_message);
    },

    setPaymentProcessing({ commit }, value) {
        commit('SET_PAYMENT_PROCESSING', value);
    },

    async createTransaction({ commit, getters, rootGetters }) {
        const response = await this.$axios.$post('/payment/client/token', {
            order_id: rootGetters['basket/id'],
            gateway: getters.gateway,
        });

        commit('SET_TRANSACTION', response);
    },

    async setGateway({ commit }, gateway) {
        await commit('SET_GATEWAY', gateway);
    },

    async setInitiativeContext({ commit }, initiativeContext) {
        await commit('SET_INITIATIVE_CONTEXT', initiativeContext);
    },
};
